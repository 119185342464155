export default [
  {
    path: '/mailbox/email',
    name: 'mail.index',
    component: () => import('@/views/Mailbox/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      canAddNav: true,
      navActiveLink: 'mail.index',
      action: 'index',
      resource: 'Mail',
    },
  },
    // TODO: Change route name
  {
    path: '/mailbox/email/:folder',
    name: 'apps-email-folder',
    component: () => import('@/views/Mailbox/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      canAddNav: true,
      navActiveLink: 'mail.index',
      action: 'index',
      resource: 'Mail',
    },
    beforeEnter(to, _, next) {
      if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  // TODO: Change route name
  {
    path: '/mailbox/email/label/:label',
    name: 'apps-email-label',
    component: () => import('@/views/Mailbox/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      canAddNav: true,
      navActiveLink: 'mail.index',
      action: 'index',
      resource: 'Mail',
    },
    beforeEnter(to, _, next) {
      if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },
]
