export default [
  {
    path: '/admin/management/templates/onlinehelp',
    name: 'onlinehelp.index',
    component: () => import('@/views/Admin/Management/Templates/OnlineHelps/OnlineHelps.vue'),
    meta: {
      canAddNav: true,
      pageTitle: 'Sprachen',
      resource: "OnlineHelp",
      breadcrumb: [
        {
          text: 'Sprachen',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/management/templates/onlinehelp/create',
    name: 'onlinehelp.create',
    component: () => import('@/views/Admin/Management/Templates/OnlineHelps/OnlineHelpsCreate.vue'),
    meta: {
      canAddNav: true,
      navActiveLink: 'onlinehelp.index',
      pageTitle: 'Online-Hilfe hinzufügen',
      resource: "OnlineHelp",
      breadcrumb: [
        {
          text: 'Online-Hilfe',
          to: '/admin/management/templates/onlinehelp',
          active: false,
        },
        {
          text: 'Hinzufügen',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/management/templates/onlinehelp/edit/:id',
    name: 'onlinehelp.edit',
    component: () => import('@/views/Admin/Management/Templates/OnlineHelps/OnlineHelpsEdit.vue'),
    meta: {
      navActiveLink: 'onlinehelp.index',
      pageTitle: 'Online-Hilfe bearbeiten',
      resource: "OnlineHelp",
      breadcrumb: [
        {
          text: 'Online-Hilfe',
          to: '/admin/management/templates/onlinehelp',
          active: false,
        },
        {
          text: 'Bearbeiten',
          active: true,
        },
      ],
    },
  },
]