export default [
  {
    path: '/user-invitation',
    name: 'User.invite',
    component: () => import('@/views/UserInvitation/index.vue'),
    meta: {
      canAddNav: true,
      pageTitle: 'Benutzer',
      resource: 'User',
      action: 'invite',
      breadcrumb: [
        {
          text: 'Benutzer',
          active: true,
        },
      ],
    },
  },
]
