export default [
  {
    path: '/admin/management/templates/systemmessage',
    name: 'systemmessage.index',
    component: () => import('@/views/Admin/Management/Templates/SystemMessages/SystemMessages.vue'),
    meta: {
      canAddNav: true,
      pageTitle: 'System-Meldungen',
      resource: "SystemMessage",
      breadcrumb: [
        {
          text: 'System-Meldungen',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/management/templates/systemmessage/create',
    name: 'systemmessage.create',
    component: () => import('@/views/Admin/Management/Templates/SystemMessages/SystemMessagesCreate.vue'),
    meta: {
      canAddNav: true,
      navActiveLink: 'systemmessage.index',
      pageTitle: 'System-Meldung hinzufügen',
      resource: "SystemMessage",
      breadcrumb: [
        {
          text: 'System-Meldung',
          to: '/admin/management/templates/systemmessage',
          active: false,
        },
        {
          text: 'Hinzufügen',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/management/templates/systemmessage/edit/:id',
    name: 'systemmessage.edit',
    component: () => import('@/views/Admin/Management/Templates/SystemMessages/SystemMessagesEdit.vue'),
    meta: {
      navActiveLink: 'systemmessage.index',
      pageTitle: 'System-Meldung bearbeiten',
      resource: "SystemMessage",
      breadcrumb: [
        {
          text: 'System-Meldung',
          to: '/admin/management/templates/systemmessage',
          active: false,
        },
        {
          text: 'Bearbeiten',
          active: true,
        },
      ],
    },
  },
]