export default [
  {
    path: '/jobs',
    name: 'job.index',
    component: () => import('@/views/Joblist/Job/JobOverwiev.vue'),
    meta: {
      canAddNav: true,
      pageTitle: 'Jobs',
      resource: 'Job',
      action: 'index',
      breadcrumb: [
        {
          text: 'Übersicht',
          active: true,
        },
      ],
    },
  },
  {
    path: '/jobs',
    name: 'job.myjobs',
    component: () => import('@/views/Joblist/Job/MyJob.vue'),
    meta: {
      canAddNav: true,
      pageTitle: 'Jobs',
      resource: 'Job',
      action: 'myjobs',
      breadcrumb: [
        {
          text: 'Meine Jobs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/job/create',
    name: 'job.create',
    component: () => import('@/views/Joblist/Job/JobCreate.vue'),
    meta: {
      canAddNav: true,
      navActiveLink: 'job.index',
      pageTitle: 'Job hinzufügen',
      resource: 'Job',
      action: 'create',
      breadcrumb: [
        {
          text: 'Jobs',
          to: '/jobs',
          active: false,
        },
        {
          text: 'Hinzufügen',
          active: true,
        },
      ],
    },
  },
  {
    path: '/job/show/:id',
    name: 'job.show',
    component: () => import('@/views/Joblist/Job/JobShow.vue'),
    meta: {
      navActiveLink: 'job.index',
      pageTitle: 'Jobdetails',
      resource: 'Job',
      action: 'show',
      breadcrumb: [
        {
          text: 'Jobs',
          to: '/jobs',
          active: false,
        },
        {
          text: 'Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/job/edit/:id',
    name: 'job.edit',
    component: () => import('@/views/Joblist/Job/JobEdit.vue'),
    meta: {
      navActiveLink: 'job.index',
      pageTitle: 'Job bearbeiten',
      resource: 'Job',
      action: 'edit',
      breadcrumb: [
        {
          text: 'Jobs',
          to: '/jobs',
          active: false,
        },
        {
          text: 'Bearbeiten',
          active: true,
        },
      ],
    },
  },
]
