export default [
  {
    path: '/admin/management/templates/helptext',
    name: 'helptext.index',
    component: () => import('@/views/Admin/Management/Templates/HelpTexts/HelpTexts.vue'),
    meta: {
      canAddNav: true,
      pageTitle: 'Hilfetexte',
      resource: "HelpText",
      breadcrumb: [
        {
          text: 'Hilfetexte',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/management/templates/helptext/create',
    name: 'helptext.create',
    component: () => import('@/views/Admin/Management/Templates/HelpTexts/HelpTextsCreate.vue'),
    meta: {
      canAddNav: true,
      navActiveLink: 'helptext.index',
      pageTitle: 'Hilfetext hinzufügen',
      resource: "HelpText",
      breadcrumb: [
        {
          text: 'Hilfetext',
          to: '/admin/management/templates/helptext',
          active: false,
        },
        {
          text: 'Hinzufügen',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/management/templates/helptext/edit/:id',
    name: 'helptext.edit',
    component: () => import('@/views/Admin/Management/Templates/HelpTexts/HelpTextsEdit.vue'),
    meta: {
      navActiveLink: 'helptext.index',
      pageTitle: 'Hilfetext bearbeiten',
      resource: "HelpText",
      breadcrumb: [
        {
          text: 'Hilfetext',
          to: '/admin/management/templates/helptext',
          active: false,
        },
        {
          text: 'Bearbeiten',
          active: true,
        },
      ],
    },
  },
]