export default [
  {
    path: '/admin/faq',
    name: 'Faq.index',
    component: () => import('@/views/Faq/Faq.vue'),
    meta: {
      canAddNav: true,
      pageTitle: 'FAQ',
      resource: 'Faq',
      breadcrumb: [
        {
          text: 'FAQ',
          active: true,
        },
      ],
    },
  },

]
