import documentdefinition from './documentdefinition'
import documentcomment from './documentcomment'
import documenterrormessagemanagement from './documenterrormessagemanagement'
import documentcontract from './documentcontract'

export default [
  ...documentdefinition,
  ...documentcomment,
  ...documenterrormessagemanagement,
  ...documentcontract,
]
