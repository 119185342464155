import Vue from 'vue'
import VueRouter from 'vue-router'

import { canNavigate } from '@/libs/acl/routeProtection'
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'

// Routes
import modules from './routes/modules/modules'
import authentication from './routes/authentication'
import errors from './routes/errors'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        canAddNav: true,
        pageTitle: 'Home',
        resource: 'Dashboard',
        action: 'canShowDashboard',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/template',
      name: 'template',
      component: () => import('@/views/TemplatePage.vue'),
      meta: {
        canAddNav: true,
        pageTitle: 'Platzhalter Seite',
        resource: 'Placeholder',
        action: 'index',
        breadcrumb: [
          {
            text: 'Platzhalter Seite',
            active: true,
          },
        ],
      },
    },
    {
      path: '/logging',
      name: 'logging.index',
      component: () => import('@/views/Logging/Logging.vue'),
      meta: {
        pageTitle: 'Logging',
        resource: 'Logging',
        action: 'index',
      },
    },

    ...modules,
    ...authentication,
    ...errors,
  ],
})

router.beforeEach(async (to, _, next) => {
  const isLoggedIn = await isUserLoggedIn()

  if (to.meta.typ == 'no-auth') {
    return next()
  }

  if (!isLoggedIn) return next({ name: 'auth-login' })

  if (!canNavigate(to)) {
    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()

    return next(getHomeRouteForLoggedInUser(userData ? userData.userRole : null))
    // return next('/home')
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
