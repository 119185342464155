export default [
  {
    path: '/admin/management/templates/placeholder',
    name: 'placeholder.index',
    component: () => import('@/views/Admin/Management/Templates/Placeholder/Placeholder.vue'),
    meta: {
      canAddNav: true,
      pageTitle: 'Platzhalter',
      resource: "Placeholder",
      breadcrumb: [
        {
          text: 'Platzhalter',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/management/templates/placeholder/create',
    name: 'placeholder.create',
    component: () => import('@/views/Admin/Management/Templates/Placeholder/PlaceholderCreate.vue'),
    meta: {
      canAddNav: true,
      navActiveLink: 'placeholder.index',
      pageTitle: 'Platzhalter hinzufügen',
      resource: "Placeholder",
      breadcrumb: [
        {
          text: 'Platzhalter',
          to: '/admin/management/templates/placeholder',
          active: false,
        },
        {
          text: 'Hinzufügen',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/management/templates/placeholder/edit/:id',
    name: 'placeholder.edit',
    component: () => import('@/views/Admin/Management/Templates/Placeholder/PlaceholderEdit.vue'),
    meta: {
      navActiveLink: 'placeholder.index',
      pageTitle: 'Platzhalter bearbeiten',
      resource: "Placeholder",
      breadcrumb: [
        {
          text: 'Platzhalter',
          to: '/admin/management/templates/placeholder',
          active: false,
        },
        {
          text: 'Bearbeiten',
          active: true,
        },
      ],
    },
  },
]