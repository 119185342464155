import emailtemplate from "./emailtemplate"
import systemmessage from "./systemmessage"
import helptext from "./helptext"
import onlinehelp from "./onlinehelp"
import placeholder from "./placeholder"

export default [
    ...emailtemplate,
    ...systemmessage,
    ...helptext,
    ...onlinehelp,
    ...placeholder
]