import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import vSelect from 'vue-select'
import OtpInput from '@bachdgvn/vue-otp-input'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import 'vue-select/dist/vue-select.css'

Vue.component('v-select', vSelect)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
// Composition API
Vue.use(VueCompositionAPI)

Vue.use(CKEditor)

Vue.component('v-otp-input', OtpInput)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

document.title = process.env.VUE_APP_NAME || 'Safedriver'
