import useJwt from '@/auth/jwt/useJwt'
import axios from 'axios'

async function checkToken() {
  const token = useJwt.getToken();


  const data = await axios({
    method: "GET",
    url: process.env.VUE_APP_BASE_URL + '/user/checktoken',
    headers: {
      Authorization: `Bearer ${token}`,
      'content-type': 'text/json'
    }
  })

  return data
}

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = async () => {
  var tokenvalid

  // ToDo: Z.C. einkommentieren/auskommentieren falls der Log-In nicht funktioniert

  await checkToken().then((dat) => {
     tokenvalid = dat.data.token_valid
  })

  //tokenvalid = true;
  //return true;

  if(localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName) && tokenvalid) {
     return true;
   } else {
     return false;
  }

}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return 'home'
  if (userRole === 'client') return 'home' 
  return 'home'
}
