export default [
  {
    path: '/admin/management/templates/emailtemplate',
    name: 'emailtemplate.index',
    component: () => import('@/views/Admin/Management/Templates/EmailTemplates/EmailTemplates.vue'),
    meta: {
      canAddNav: true,
      pageTitle: 'E-Mail-Vorlagen',
      resource: "EmailTemplate",
      breadcrumb: [
        {
          text: 'E-Mail-Vorlagen',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/management/templates/emailtemplate/create',
    name: 'emailtemplate.create',
    component: () => import('@/views/Admin/Management/Templates/EmailTemplates/EmailTemplatesCreate.vue'),
    meta: {
      canAddNav: true,
      navActiveLink: 'emailtemplate.index',
      pageTitle: 'E-Mail-Vorlage hinzufügen',
      resource: "EmailTemplate",
      breadcrumb: [
        {
          text: 'E-Mail-Vorlage',
          to: '/admin/management/templates/emailtemplate',
          active: false,
        },
        {
          text: 'Hinzufügen',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/management/templates/emailtemplate/edit/:id',
    name: 'emailtemplate.edit',
    component: () => import('@/views/Admin/Management/Templates/EmailTemplates/EmailTemplatesEdit.vue'),
    meta: {
      navActiveLink: 'emailtemplate.index',
      pageTitle: 'E-Mail-Vorlage bearbeiten',
      resource: "EmailTemplate",
      breadcrumb: [
        {
          text: 'E-Mail-Vorlage',
          to: '/admin/management/templates/emailtemplate',
          active: false,
        },
        {
          text: 'Bearbeiten',
          active: true,
        },
      ],
    },
  },
]