export default [
  {
    path: '/admin/news',
    name: 'news.index',
    component: () => import('@/views/News/News.vue'),
    meta: {
      canAddNav: true,
      navActiveLink: 'News.index',
      action: 'index',
      resource: 'News',
      pageTitle: 'News',
    },
  },

  {
    path: '/admin/news/fdl',
    name: 'news.fdl',
    component: () => import('@/views/NewsFDL/News.vue'),
    meta: {
      canAddNav: true,
      navActiveLink: 'News.fdl',
      action: 'index',
      resource: 'News_FDL',
      pageTitle: 'News (FDL)',
    },
  },
]
