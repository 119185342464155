export default [
  {
    path: '/admin/management/documents/documenterrormessagemanagement',
    name: 'documenterrormessagemanagement.index',
    component: () => import('@/views/Admin/Management/Documents/DocumentErrorMessageManagement/DocumentErrorMessageManagement.vue'),
    meta: {
      canAddNav: true,
      pageTitle: 'Fehlermeldung (Dokumente)',
      resource: "DocumentErrorMessageManagement",
      breadcrumb: [
        {
          text: 'Fehlermeldung (Dokumente)',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/management/documents/documenterrormessagemanagement/create',
    name: 'documenterrormessagemanagement.create',
    component: () => import('@/views/Admin/Management/Documents/DocumentErrorMessageManagement/DocumentErrorMessageManagementCreate.vue'),
    meta: {
      canAddNav: true,
      navActiveLink: 'documenterrormessagemanagement.index',
      pageTitle: 'Fehlermeldung (Dokumente) hinzufügen',
      resource: "DocumentErrorMessageManagement",
      breadcrumb: [
        {
          text: 'Fehlermeldung (Dokumente)',
          to: '/admin/management/documents/documenterrormessagemanagement',
          active: false,
        },
        {
          text: 'Hinzufügen',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/management/documents/documenterrormessagemanagement/edit/:id',
    name: 'documenterrormessagemanagement.edit',
    component: () => import('@/views/Admin/Management/Documents/DocumentErrorMessageManagement/DocumentErrorMessageManagementEdit.vue'),
    meta: {
      navActiveLink: 'documenterrormessagemanagement.index',
      pageTitle: 'Fehlermeldung (Dokumente) bearbeiten',
      resource: "DocumentErrorMessageManagement",
      breadcrumb: [
        {
          text: 'Fehlermeldung (Dokumente)',
          to: '/admin/management/documents/documenterrormessagemanagement',
          active: false,
        },
        {
          text: 'Bearbeiten',
          active: true,
        },
      ],
    },
  },
]