export default [
  {
    path: '/admin/management/settings/staffclass',
    name: 'staffclass.index',
    component: () => import('@/views/Admin/Management/Settings/StaffClass/StaffClass.vue'),
    meta: {
      canAddNav: true,
      pageTitle: 'Mitarbeiterklassen',
      resource: "StaffClass",
      breadcrumb: [
        {
          text: 'Mitarbeiterklassen',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/management/settings/staffclass/create',
    name: 'staffclass.create',
    component: () => import('@/views/Admin/Management/Settings/StaffClass/StaffClassCreate.vue'),
    meta: {
      canAddNav: true,
      navActiveLink: 'staffclass.index',
      pageTitle: 'Mitarbeiterklasse hinzufügen',
      resource: "StaffClass",
      breadcrumb: [
        {
          text: 'Mitarbeiterklassen',
          to: '/admin/management/settings/staffclass',
          active: false,
        },
        {
          text: 'Hinzufügen',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/management/settings/staffclass/edit/:id',
    name: 'staffclass.edit',
    component: () => import('@/views/Admin/Management/Settings/StaffClass/StaffClassEdit.vue'),
    meta: {
      pageTitle: 'Mitarbeiterklasse bearbeiten',
      navActiveLink: 'staffclass.index',
      resource: "StaffClass",
      breadcrumb: [
        {
          text: 'Mitarbeiterklassen',
          to: '/admin/management/settings/staffclass',
          active: false,
        },
        {
          text: 'Bearbeiten',
          active: true,
        },
      ],
    },
  },
]