export default [
  {
    path: '/admin/management/settings/materiallist',
    name: 'materiallist.index',
    component: () => import('@/views/Admin/Management/Settings/MaterialList/MaterialList.vue'),
    meta: {
      canAddNav: true,
      pageTitle: 'Material-Liste',
      resource: "MaterialList",
      breadcrumb: [
        {
          text: 'Material-Liste',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/management/settings/materiallist/create',
    name: 'materiallist.create',
    component: () => import('@/views/Admin/Management/Settings/MaterialList/MaterialListCreate.vue'),
    meta: {
      canAddNav: true,
      navActiveLink: 'materiallist.index',
      pageTitle: 'Material-Liste hinzufügen',
      resource: "MaterialList",
      breadcrumb: [
        {
          text: 'Material-Liste',
          to: '/admin/management/settings/materiallist',
          active: false,
        },
        {
          text: 'Hinzufügen',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/management/settings/materiallist/edit/:id',
    name: 'materiallist.edit',
    component: () => import('@/views/Admin/Management/Settings/MaterialList/MaterialListEdit.vue'),
    meta: {
      navActiveLink: 'materiallist.index',
      pageTitle: 'Material-Liste bearbeiten',
      resource: "MaterialList",
      breadcrumb: [
        {
          text: 'Material-Liste',
          to: '/admin/management/settings/materiallist',
          active: false,
        },
        {
          text: 'Bearbeiten',
          active: true,
        },
      ],
    },
  },
]