export default [
  {
    path: "/roles",
    name: "roles.index",
    component: () => import("@/views/User/ACL/Roles/Role.vue"),
    meta: {
      canAddNav: true,
      action: "index",
      resource: "Roles",
      pageTitle: "Rollen",
      breadcrumb: [
        {
          text: "Rollen",
          active: true,
        },
      ],
    },
  },
  {
    path: "/roles/create",
    name: "roles.create",
    component: () => import("@/views/User/ACL/Roles/RoleCreate.vue"),
    meta: {
      canAddNav: true,
      navActiveLink: "roles.index",
      action: "edit",
      resource: "Roles",
      pageTitle: "Rolle hinzufügen",
      breadcrumb: [
        {
          text: "Rollen",
          to: { name: "roles.index" },
          active: false,
        },
        {
          text: "Hinzufügen",
          active: true,
        },
      ],
    },
  },
  {
    path: "/roles/edit/:id",
    name: "roles.edit",
    component: () => import("@/views/User/ACL/Roles/RoleEdit.vue"),
    meta: {
      pageTitle: "Rolle bearbeiten",
      action: "edit",
      resource: "Roles",
      breadcrumb: [
        {
          text: "Rollen",
          to: { name: "roles.index" },
          active: false,
        },
        {
          text: "Bearbeiten",
          active: true,
        },
      ],
    },
  },
  {
    path: "/roles/copy/:id",
    name: "roles.copy",
    component: () => import("@/views/User/ACL/Roles/RoleEdit.vue"),
    meta: {
      pageTitle: "Rolle kopieren",
      action: "edit",
      resource: "Roles",
      breadcrumb: [
        {
          text: "Rollen",
          to: { name: "roles.index" },
          active: false,
        },
        {
          text: "Kopieren",
          active: true,
        },
      ],
    },
  },
];
