export default [
  {
    path: '/admin/management/settings/eventtype',
    name: 'eventtype.index',
    component: () => import('@/views/Admin/Management/Settings/EventType/EventType.vue'),
    meta: {
      canAddNav: true,
      pageTitle: 'Jobarten',
      resource: "EventType",
      breadcrumb: [
        {
          text: 'Jobarten',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/management/settings/eventtype/create',
    name: 'eventtype.create',
    component: () => import('@/views/Admin/Management/Settings/EventType/EventTypeCreate.vue'),
    meta: {
      canAddNav: true,
      navActiveLink: 'eventtype.index',
      pageTitle: 'Jobart hinzufügen',
      resource: "EventType",
      action: "create",
      breadcrumb: [
        {
          text: 'Jobarten',
          to: '/admin/management/settings/eventtype',
          active: false,
        },
        {
          text: 'Hinzufügen',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/management/settings/eventtype/edit/:id',
    name: 'eventtype.edit',
    component: () => import('@/views/Admin/Management/Settings/EventType/EventTypeEdit.vue'),
    meta: {
      navActiveLink: 'eventtype.index',
      pageTitle: 'Jobart bearbeiten',
      resource: "EventType",
      action: "edit",
      breadcrumb: [
        {
          text: 'Jobarten',
          to: '/admin/management/settings/eventtype',
          active: false,
        },
        {
          text: 'Bearbeiten',
          active: true,
        },
      ],
    },
  },
]