export default [
  {
    path: '/event',
    name: 'event.index',
    component: () => import('@/views/Joblist/Event/Event.vue'),
    meta: {
      canAddNav: true,
      pageTitle: 'Events',
      resource: 'Event',
      action: 'index',
      breadcrumb: [
        {
          text: 'Events',
          active: true,
        },
      ],
    },
  },
  {
    path: '/event/create',
    name: 'event.create',
    component: () => import('@/views/Joblist/Event/EventCreate.vue'),
    meta: {
      canAddNav: true,
      navActiveLink: 'event.index',
      pageTitle: 'Event hinzufügen',
      resource: 'Event',
      action: 'create',
      breadcrumb: [
        {
          text: 'Events',
          to: '/event',
          active: false,
        },
        {
          text: 'Hinzufügen',
          active: true,
        },
      ],
    },
  },
  {
    path: '/event/show/:id',
    name: 'event.show',
    component: () => import('@/views/Joblist/Event/EventShow.vue'),
    meta: {
      navActiveLink: 'event.index',
      pageTitle: 'Event Details',
      resource: 'Event',
      action: 'show',
      breadcrumb: [
        {
          text: 'Events',
          to: '/event',
          active: false,
        },
        {
          text: 'Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/event/edit/:id',
    name: 'event.edit',
    component: () => import('@/views/Joblist/Event/EventEdit.vue'),
    meta: {
      navActiveLink: 'event.index',
      pageTitle: 'Event bearbeiten',
      resource: 'Event',
      action: 'edit',
      breadcrumb: [
        {
          text: 'Events',
          to: '/event',
          active: false,
        },
        {
          text: 'Bearbeiten',
          active: true,
        },
      ],
    },
  },
]
