export default [
  {
    path: '/admin/onboarding',
    name: 'Onboarding.invite',
    component: () => import('@/views/Onboarding/index.vue'),
    meta: {
      canAddNav: true,
      pageTitle: 'Onboarding',
      resource: 'Onboarding',
      action: 'invite',
      breadcrumb: [
        {
          text: 'Massen Einladungen',
          active: true,
        },
      ],
    },
  },
]
