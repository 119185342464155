export default [
  {
    path: '/admin/management/driversearch/documentcheck',
    name: 'documentcheck.index',
    component: () => import('@/views/Admin/Management/DriverSearchOverview/DocumentCheck/DocumentCheck.vue'),
    meta: {
      canAddNav: true,
      pageTitle: 'Dokumenten-Prüfung',
      resource: 'DocumentCheck',
      breadcrumb: [
        {
          text: 'Dokumenten-Prüfung',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/management/driversearch/documentcheck/doc/:id',
    name: 'documentcheck.show',
    component: () => import('@/views/Admin/Management/DriverSearchOverview/DocumentCheck/DocumentCheckItem.vue'),
    meta: {
      navActiveLink: 'documentcheck.index',
      pageTitle: 'Dokument prüfen',
      resource: 'DocumentCheck',
      action: 'show',
      breadcrumb: [
        {
          text: 'Dokumenten-Prüfung',
          to: '/admin/management/driversearch/documentcheck',
          active: false,
        },
        {
          text: 'Dokumente',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/management/driversearch/documentcheck/check/:id',
    name: 'documentcheck.edit',
    component: () => import('@/views/Admin/Management/DriverSearchOverview/DocumentCheck/DocumentCheckEdit.vue'),
    meta: {
      navActiveLink: 'documentcheck.index',
      pageTitle: 'Dokument prüfen',
      resource: 'DocumentCheck',
      action: 'edit',
      breadcrumb: [
        {
          text: 'Dokumenten-Prüfung',
          to: '/admin/management/driversearch/documentcheck',
          active: false,
        },
        {
          text: 'Dokumente',
          active: true,
        },
      ],
    },
  },
]
