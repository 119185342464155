export default [
  {
    path: '/admin/management/documents/documentcontract',
    name: 'documentcontract.index',
    component: () => import('@/views/Admin/Management/Documents/DocumentContract/DocumentContract.vue'),
    meta: {
      canAddNav: true,
      pageTitle: 'Verträge',
      resource: "DocumentContract",
      breadcrumb: [
        {
          text: 'Verträge',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/management/documents/documentcontract/create',
    name: 'documentcontract.create',
    component: () => import('@/views/Admin/Management/Documents/DocumentContract/DocumentContractCreate.vue'),
    meta: {
      canAddNav: true,
      navActiveLink: 'documentcontract.index',
      pageTitle: 'Vertrag hinzufügen',
      resource: "DocumentContract",
      breadcrumb: [
        {
          text: 'Verträge',
          to: '/admin/management/documents/documentcontract',
          active: false,
        },
        {
          text: 'Hinzufügen',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/management/documents/documentcontract/edit/:id',
    name: 'documentcontract.edit',
    component: () => import('@/views/Admin/Management/Documents/DocumentContract/DocumentContractEdit.vue'),
    meta: {
      navActiveLink: 'documentcontract.index',
      pageTitle: 'Vertrag bearbeiten',
      resource: "DocumentContract",
      breadcrumb: [
        {
          text: 'Verträge',
          to: '/admin/management/documents/documentcontract',
          active: false,
        },
        {
          text: 'Bearbeiten',
          active: true,
        },
      ],
    },
  },
]