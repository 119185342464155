import user from "./user"
import property from "./property"
import fdlproperty from "./fdlproperty"
import navigation from "./navigation"
import staffclass from "./staffclass"
import fdlposition from "./fdlposition"
import materiallist from "./materiallist"
import eventtype from "./eventtype"
import roles from "./roles"

export default [
    ...user,
    ...roles,
    ...fdlproperty,
    ...property,
    ...navigation,
    ...staffclass,
    ...fdlposition,
    ...materiallist,
    ...eventtype
]