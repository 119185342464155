export default [
  {
    path: '/admin/error_reporting',
    name: 'error_reporting.index',
    component: () => import('@/views/ErrorReporting/index.vue'),
    meta: {
      canAddNav: true,
      pageTitle: 'Fehlerlogs',
      resource: 'error_reporting',
      action: 'index',
      breadcrumb: [
        {
          text: 'Übersicht',
          active: true,
        },
      ],
    },
  },
]
