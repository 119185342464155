export default [
  {
    path: '/project',
    name: 'project.index',
    component: () => import('@/views/Joblist/Project/Project.vue'),
    meta: {
      canAddNav: true,
      pageTitle: 'Projekte',
      resource: 'Project',
      action: 'index',
      breadcrumb: [
        {
          text: 'Projekte',
          active: true,
        },
      ],
    },
  },
  {
    path: '/project/create',
    name: 'project.create',
    component: () => import('@/views/Joblist/Project/ProjectCreate.vue'),
    meta: {
      canAddNav: true,
      navActiveLink: 'project.index',
      pageTitle: 'Projekt hinzufügen',
      resource: 'Project',
      action: 'create',
      breadcrumb: [
        {
          text: 'Projekte',
          to: '/project',
          active: false,
        },
        {
          text: 'Hinzufügen',
          active: true,
        },
      ],
    },
  },
  {
    path: '/project/show/:id',
    name: 'project.show',
    component: () => import('@/views/Joblist/Project/ProjectShow.vue'),
    meta: {
      pageTitle: 'Projekt Details',
      navActiveLink: 'project.index',
      resource: 'Project',
      action: 'show',
      breadcrumb: [
        {
          text: 'Projekte',
          to: '/project',
          active: false,
        },
        {
          text: 'Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/project/edit/:id',
    name: 'project.edit',
    component: () => import('@/views/Joblist/Project/ProjectEdit.vue'),
    meta: {
      pageTitle: 'Projekt bearbeiten',
      navActiveLink: 'project.index',
      resource: 'Project',
      action: 'edit',
      breadcrumb: [
        {
          text: 'Projekte',
          to: '/project',
          active: false,
        },
        {
          text: 'Bearbeiten',
          active: true,
        },
      ],
    },
  },
]
