import job from './job'
import event from './event'
import project from './project'
import jobinfo from './jobinfo'

export default [
  ...job,
  ...event,
  ...project,
  ...jobinfo,
]
