export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Authentication/Login.vue'),
    meta: {
      typ: 'no-auth',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/Authentication/ForgotPassword.vue'),
    meta: {
      typ: 'no-auth',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password-success',
    name: 'forgot-password-success',
    component: () => import('@/views/Authentication/PasswordResetSuccessConfirmation.vue'),
    meta: {
      typ: 'no-auth',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/Authentication/ResetPassword.vue'),
    meta: {
      typ: 'no-auth',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/registration',
    name: 'onboarding',
    component: () => import('@/views/Authentication/OneTimePasswordRegistration.vue'),
    meta: {
      typ: 'no-auth',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
]
