export default [
    {
        path: '/fdlproperty',
        name: 'fdlproperty.index',
        component: () => import('@/views/FDLProperty/FDLProperty.vue'),
        meta: {
          canAddNav: true,
          pageTitle: 'FDL-Eigenschaften',
          resource: "FDLProperty",
          breadcrumb: [
            {
              text: 'FDL-Eigenschaften',
              active: true,
            },
          ],
        },
      },
      {
        path: '/fdlproperty/create',
        name: 'fdlproperty.create',
        component: () => import('@/views/FDLProperty/FDLPropertyCreate.vue'),
        meta: {
          canAddNav: true,
          navActiveLink: 'fdlproperty.index',
          pageTitle: 'FDL-Eigenschaften hinzufügen',
          resource: "FDLProperty",
          breadcrumb: [
            {
              text: 'FDL-Eigenschaften',
              to: '/fdlproperty',
              active: false,
            },
            {
              text: 'Hinzufügen',
              active: true,
            },
          ],
        },
      },
      {
        path: '/fdlproperty/edit/:id',
        name: 'fdlproperty.edit',
        component: () => import('@/views/FDLProperty/FDLPropertyEdit.vue'),
        meta: {
          navActiveLink: 'fdlproperty.index',
          pageTitle: 'FDL-Eigenschaften bearbeiten',
          resource: "FDLProperty",
          breadcrumb: [
            {
              text: 'FDL-Eigenschaften',
              to: '/fdlproperty',
              active: false,
            },
            {
              text: 'Bearbeiten',
              active: true,
            },
          ],
        },
      },
]