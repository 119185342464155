export default [
  {
    path: '/admin/contracts/templates',
    name: 'ContractTemplates.showContractTemplates',
    component: () => import('@/views/ContractTemplates/index.vue'),
    meta: {
      canAddNav: true,
      pageTitle: 'Vertragsvorlagen',
      resource: 'ContractTemplates',
      action: 'showContractTemplates',
      breadcrumb: [
        {
          text: 'Templates',
          active: true,
        },
      ],
    },
  },
]
