export default [
  {
    path: '/admin/management/settings/fdlposition',
    name: 'fdlposition.index',
    component: () => import('@/views/Admin/Management/Settings/FdlPosition/FdlPosition.vue'),
    meta: {
      canAddNav: true,
      pageTitle: 'FDL-Position',
      resource: "FDLPosition",
      breadcrumb: [
        {
          text: 'FDL-Position',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/management/settings/fdlposition/create',
    name: 'fdlposition.create',
    component: () => import('@/views/Admin/Management/Settings/FdlPosition/FdlPositionCreate.vue'),
    meta: {
      canAddNav: true,
      navActiveLink: 'fdlposition.index',
      pageTitle: 'FDL-Position hinzufügen',
      resource: "FDLPosition",
      breadcrumb: [
        {
          text: 'FDL-Position',
          to: '/admin/management/settings/fdlposition',
          active: false,
        },
        {
          text: 'Hinzufügen',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/management/settings/fdlposition/edit/:id',
    name: 'fdlposition.edit',
    component: () => import('@/views/Admin/Management/Settings/FdlPosition/FdlPositionEdit.vue'),
    meta: {
      navActiveLink: 'fdlposition.index',
      pageTitle: 'FDL-Position bearbeiten',
      resource: "FDLPosition",
      breadcrumb: [
        {
          text: 'FDL-Position',
          to: '/admin/management/settings/fdlposition',
          active: false,
        },
        {
          text: 'Bearbeiten',
          active: true,
        },
      ],
    },
  },
]