export default [
  {
    path: '/property',
    name: 'property.index',
    component: () => import('@/views/Property/Property.vue'),
    meta: {
      canAddNav: true,
      pageTitle: 'Eigenschaften',
      resource: 'Property',
      action: '',
      breadcrumb: [
        {
          text: 'Eigenschaften',
          active: true,
        },
      ],
    },
  },
  {
    path: '/property/create',
    name: 'property.create',
    component: () => import('@/views/Property/PropertyCreate.vue'),
    meta: {
      canAddNav: true,
      navActiveLink: 'property.index',
      pageTitle: 'Eigenschaften hinzufügen',
      resource: 'Property',
      breadcrumb: [
        {
          text: 'Eigenschaften',
          to: '/property',
          active: false,
        },
        {
          text: 'Hinzufügen',
          active: true,
        },
      ],
    },
  },
  {
    path: '/property/edit/:id',
    name: 'property.edit',
    component: () => import('@/views/Property/PropertyEdit.vue'),
    meta: {
      navActiveLink: 'property.index',
      pageTitle: 'Eigenschaften bearbeiten',
      resource: 'Property',
      breadcrumb: [
        {
          text: 'Eigenschaften',
          to: '/property',
          active: false,
        },
        {
          text: 'Bearbeiten',
          active: true,
        },
      ],
    },
  },
]
