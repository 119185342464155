export default [
  {
    path: '/admin/contracts',
    name: 'Contracts.seeContractsOverview',
    component: () => import('@/views/Contracts/index.vue'),
    meta: {
      canAddNav: true,
      pageTitle: 'Verträge',
      resource: 'Contracts',
      action: 'seeContractsOverview',
      breadcrumb: [
        {
          text: 'Verträge',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/mycontracts',
    name: 'Contracts.seeMyContracts',
    component: () => import('@/views/Contracts/components/MyContracts.vue'),
    meta: {
      canAddNav: true,
      pageTitle: 'Meine Verträge',
      resource: 'Contracts',
      action: 'seeMyContracts',
      breadcrumb: [
        {
          text: 'Meine Verträge',
          active: true,
        },
      ],
    },
  },
]
