export default [
  {
    path: '/admin/management/documents/documentdefinition',
    name: 'documentdefinition.index',
    component: () => import('@/views/Admin/Management/Documents/DocumentDefinition/DocumentDefinition.vue'),
    meta: {
      canAddNav: true,
      pageTitle: 'Dokumentdefinition',
      resource: "DocumentDefinition",
      breadcrumb: [
        {
          text: 'Dokumentdefinition',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/management/documents/documentdefinition/create',
    name: 'documentdefinition.create',
    component: () => import('@/views/Admin/Management/Documents/DocumentDefinition/DocumentDefinitionCreate.vue'),
    meta: {
      canAddNav: true,
      navActiveLink: 'documentdefinition.index',
      pageTitle: 'Dokumentdefinition hinzufügen',
      resource: "DocumentDefinition",
      breadcrumb: [
        {
          text: 'Dokumentdefinition',
          to: '/admin/management/documents/documentdefinition',
          active: false,
        },
        {
          text: 'Hinzufügen',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/management/documents/documentdefinition/edit/:id',
    name: 'documentdefinition.edit',
    component: () => import('@/views/Admin/Management/Documents/DocumentDefinition/DocumentDefinitionEdit.vue'),
    meta: {
      navActiveLink: 'documentdefinition.index',
      pageTitle: 'Dokumentdefinition bearbeiten',
      resource: "DocumentDefinition",
      breadcrumb: [
        {
          text: 'Dokumentdefinition',
          to: '/admin/management/documents/documentdefinition',
          active: false,
        },
        {
          text: 'Bearbeiten',
          active: true,
        },
      ],
    },
  },
]