import contracttemplates from '@/router/routes/modules/ContractTemplates/contracttemplates'
import invitation from '@/router/routes/modules/Settings/invitation'
import joblist from './Jobliste/joblist'
import driversearchoverview from './DriverSearchOverview/driversearchoverview'
import settings from './Settings/settings'
import documents from './Documents/documents'
import templates from './Templates/templates'
import news from './News/news'
import downloadcenter from './Downloadcenter/downloadcenter'
import mailbox from './Mailbox/mailbox'
import faq from './Faq/faq'
import contracts from './Contracts'
import error_reporting from './ErrorReporting'
import onboarding from './Onboarding'

export default [
  ...joblist,
  ...driversearchoverview,
  ...settings,
  ...documents,
  ...templates,
  ...news,
  ...templates,
  ...downloadcenter,
  ...mailbox,
  ...faq,
  ...contracts,
  ...contracttemplates,
  ...invitation,
  ...error_reporting,
  ...onboarding,
]
