export default [
  {
    path: "/navigation",
    name: "navigation.index",
    component: () => import("@/views/Admin/Navigation/Navigation.vue"),
    meta: {
      canAddNav: true,
      resource: "Navigation",
      action: "index",
      pageTitle: "Navigation",
      breadcrumb: [
        {
          text: "Navigation",
          active: true,
        },
      ],
    },
  },
];
