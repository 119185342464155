export default [
  {
    path: '/admin/management/documents/documentcomment',
    name: 'documentcomment.index',
    component: () => import('@/views/Admin/Management/Documents/DocumentComment/DocumentComment.vue'),
    meta: {
      canAddNav: true,
      pageTitle: 'Kommentar',
      resource: "DocumentComment",
      breadcrumb: [
        {
          text: 'Kommentar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/management/documents/documentcomment/create',
    name: 'documentcomment.create',
    component: () => import('@/views/Admin/Management/Documents/DocumentComment/DocumentCommentCreate.vue'),
    meta: {
      canAddNav: true,
      navActiveLink: 'documentcomment.index',
      pageTitle: 'Kommentar hinzufügen',
      resource: "DocumentComment",
      breadcrumb: [
        {
          text: 'Kommentar',
          to: '/admin/management/documents/documentcomment',
          active: false,
        },
        {
          text: 'Hinzufügen',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/management/documents/documentcomment/edit/:id',
    name: 'documentcomment.edit',
    component: () => import('@/views/Admin/Management/Documents/DocumentComment/DocumentCommentEdit.vue'),
    meta: {
      navActiveLink: 'documentcomment.index',
      pageTitle: 'Kommentar bearbeiten',
      resource: "DocumentComment",
      breadcrumb: [
        {
          text: 'Kommentar',
          to: '/admin/management/documents/documentcomment',
          active: false,
        },
        {
          text: 'Bearbeiten',
          active: true,
        },
      ],
    },
  },
]