export default [
  {
    path: '/user',
    name: 'user.index',
    component: () => import('@/views/User/User.vue'),
    meta: {
      canAddNav: true,
      action: 'index',
      resource: 'User',
      pageTitle: 'Benutzer',
      breadcrumb: [
        {
          text: 'Benutzer',
          active: true,
        },
      ],
    },
  },
  {
    path: '/driver-search',
    name: 'user.driver-search',
    component: () => import('@/views/User/driver-search/DriverSearch.vue'),
    meta: {
      canAddNav: true,
      action: 'driver-search',
      resource: 'User',
      pageTitle: 'Fahrersuche',
      breadcrumb: [
        {
          text: 'Fahrersuche',
          active: true,
        },
      ],
    },
  },
  {
    path: '/driver-list',
    name: 'user.driver-list',
    component: () => import('@/views/User/driver-search/DriverOverwiev.vue'),
    meta: {
      canAddNav: true,
      action: 'driver-search',
      resource: 'User',
      pageTitle: 'Übersicht',
      breadcrumb: [
        {
          text: 'Übersicht',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user/registration',
    name: 'user.registration',
    component: () => import('@/views/User/UserRegistration.vue'),
    meta: {
      typ: 'no-auth',
      canAddNav: true,
      layout: 'full',
      navActiveLink: 'user.index',
      action: 'registration',
      resource: 'User',
      pageTitle: 'Registrierung',
      breadcrumb: [
        {
          text: 'Benutzer',
          to: { name: 'user.index' },
          active: false,
        },
        {
          text: 'Registrierung',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user/create',
    name: 'user.create',
    component: () => import('@/views/User/UserCreate.vue'),
    meta: {
      typ: 'no-auth',
      canAddNav: true,
      navActiveLink: 'user.index',
      action: 'create',
      resource: 'User',
      pageTitle: 'Benutzer hinzufügen',
      breadcrumb: [
        {
          text: 'Benutzer',
          to: { name: 'user.index' },
          active: false,
        },
        {
          text: 'Hinzufügen',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user/edit/:id',
    name: 'user.edit',
    component: () => import('@/views/User/users-edit/UsersEdit.vue'),
    meta: {
      pageTitle: 'Benutzer bearbeiten',
      navActiveLink: 'user.index',
      action: 'edit',
      resource: 'User',
      breadcrumb: [
        {
          text: 'Benutzer',
          to: { name: 'user.index' },
          active: false,
        },
        {
          text: 'Bearbeiten',
          active: true,
        },
      ],
    },
  },
]
