export default [
  {
    path: "*",
    redirect: "error-404",
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/error/Error.vue"),
    meta: {
      typ: "no-auth",
      layout: "full",
    },
  },
  {
    path: "/not-authorized",
    name: "not-authorized",
    component: () => import("@/views/error/NotAuthorized.vue"),
    meta: {
      typ: "no-auth",
      layout: "full",
    },
  },
];
