export default [
  {
    path: '/jobinfo-confirm/:jid/:id',
    name: 'jobinfo.confirm',
    component: () => import('@/views/Public/JobinfoConfirm.vue'),
    meta: {
      typ: 'no-auth',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },
  {
    path: '/jobinfo/create',
    name: 'jobinfo.create',
    component: () => import('@/views/Joblist/Job/Jobinfo/JobinfoCreate.vue'),
    meta: {
      canAddNav: true,
      navActiveLink: 'jobinfo.index',
      pageTitle: 'Jobinfo erstellen',
      resource: "Jobinfo",
      action: "create",
      breadcrumb: [
        {
          text: 'Jobinfos',
          to: '/jobinfo',
          active: false,
        },
        {
          text: 'Erstellen',
          active: true,
        },
      ],
    },
  },
  {
    path: '/jobinfo/show/:id',
    name: 'jobinfo.show',
    component: () => import('@/views/Joblist/Job/Jobinfo/JobinfoShow.vue'),
    meta: {
      navActiveLink: 'jobinfo.index',
      pageTitle: 'Jobinfo-Details',
      resource: "Jobinfo",
      action: "show",
      breadcrumb: [
        {
          text: 'Jobinfos',
          to: '/jobinfo',
          active: false,
        },
        {
          text: 'Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/jobinfo/edit/:id',
    name: 'jobinfo.edit',
    component: () => import('@/views/Joblist/Job/Jobinfo/JobinfoEdit.vue'),
    meta: {
      navActiveLink: 'jobinfo.index',
      pageTitle: 'Jobinfo bearbeiten',
      resource: "Jobinfo",
      action: "edit",
      breadcrumb: [
        {
          text: 'Jobinfos',
          to: '/jobinfo',
          active: false,
        },
        {
          text: 'Bearbeiten',
          active: true,
        },
      ],
    },
  },
]
