export const initialAbility = [
  {
    action: "index",
    subject: "Auth",
  },
  {
    action: "index",
    subject: "Home",
  },
  {
    action: "create",
    subject: "User",
  },
  {
    action: "registration",
    subject: "User",
  },
];

export const additionalAbility = [
  {
    action: "index",
    subject: "Home",
  },
];

export const _ = undefined;
